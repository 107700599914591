<template>
  <div class="ingredient-item" @click="$emit('click',data)">
    <div class="image" :style="style" />
    <div class="label">{{ data.name }}</div>
    <div v-if="showFamily" class="fam-label">{{ data.family && data.family.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'IngredientItem',
  components: {},
  props: ['data', 'showFamily'],
  computed: {
    style() {
      if (this.data.imageUrl || this.data.lightImageUrl)
        return {
          backgroundImage: `url(${this.data.imageUrl || this.data.lightImageUrl})`,
        }
      else {
        return {
          backgroundColor: this.data.family ? this.data.family.color : 'white',
        }
      }
    },
  },
  methods: {},
}
</script>

<style scoped lang="stylus">
.ingredient-item
  display: flex
  flex-direction: column
  align-items: center

  .image
    margin: 1px // Para asegurar que no se corta "ni un pixel" de la redonda
    margin-bottom: vw(15px)
    width: vw(80px)
    height: vw(80px)
    border-radius: 50%
    background-position: center center
    background-size: cover
    background-repeat: no-repeat

    &:after
      display: block
      padding-bottom: 100%
      content: ""

  .label
    // m-ellipsis(100%)
    m-font-size(14, 16)
    text-align: center

  .fam-label
    //m-ellipsis(100%)
    m-font-size(12, 14)
    margin-top: vw(5px)
    color: #777
    text-align: center
</style>