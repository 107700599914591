<template>
  <basic-modal 
    class="register-modal" 
    :open="open" 
    :show-header="false"
    @close="handleCancel"
  >
    <div v-if="!next" class="container">
      <div class="title">{{ isRecommendation ? $t('not_loged_modal_title') : $t('not_loged_modal_title_wishlist') }}</div>
      <div class="description">{{ isRecommendation ? $t('not_loged_modal_description') : $t('not_loged_modal_description_wishlist') }}</div>
    </div>
    <login-card v-if="next" />
    <template v-if="!next" #footer> 
      <div class="btn-container">
        <button class="btn btn--tertiary" @click="handleAccept">{{ $t("accept") }}</button>
        <button class="btn btn--secondary btn--ghost" @click="handleCancel">{{ $t("cancel") }}</button>
      </div>
      <div class="footer-text">{{ $t('reco_not_loged_modal_footer') }}</div>
    </template>
  </basic-modal>
</template>

<script>
import BasicModal from './basic/BasicModal.vue'
import LoginCard from './LoginCard.vue'
export default {
  components: { BasicModal, LoginCard },
  model: {
    prop: 'open',
    event: 'close',
  },
  props: {
    open: {
      required: true,
      type: Boolean,
    },
    isRecommendation:{
      type:Boolean,
      required:false,
      default:true
    }
  },
  data() {
    return {
      next:false
    }
  },
  watch:{
    open(){
      if(this.open) this.next=false
    }
  },
  methods: {
    handleCancel() {
      this.$emit('close', false)
    },
    handleAccept() {
      this.next=true
    },
  },
}
</script>

<style lang="stylus" scoped>
.register-modal
  width: 100%
  height: 100%
  .secondary-btn
    background-color: $tertiary-btn-color
    color: #fff

  >>> .basic-modal-container
    padding: vh(20px) vw(20px)
    max-width: vw(600px)
    
    .container
      text-align: center

    .title
      m-font-size(18, 24)
      margin-bottom: vh(20px)
      font-weight: 700

      .mobile &
        m-font-size(20, 24)
        margin-bottom: vw(20px)

    .description
      m-font-size(16, 26)
      margin-right: auto
      margin-bottom: vh(30px)
      margin-left: auto
      width: 75%

      .mobile &
        margin-bottom: vw(30px)
        width: 100%

  .btn-container
    display: flex
    align-items: center
    width: 100%
    justify-content: center
    
    .mobile &
      display: block

    .btn
      width: vw(250px)

      .mobile &
        width: 100%
        height: vw(50px)

        & + .btn
          margin-top: vw(20px)
          margin-left: 0

  .footer-text
    m-font-size(14, 22)
    margin-top: vh(40px)
    text-align: center
</style>