export function isEquivalent(a, b) {
  if (Array.isArray(a)) {
    if (!Array.isArray(b) || a.length !== b.length) return false
    for (var j = 0; j < a.length; j++) {
      if (typeof a[j] !== typeof b[j]) return false
      switch (typeof a[j]) {
        case 'object':
          if (!isEquivalent(a[j], b[j])) return false
          break
        default:
          if (a[j] !== b[j]) return false
      }
    }
  }
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a)
  var bProps = Object.getOwnPropertyNames(b)

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i]

    // If values of same property are not equal,
    // objects are not equivalent
    if (typeof a[propName] !== typeof b[propName]) return false
    switch (typeof a[propName]) {
      case 'object':
        if (!isEquivalent(a[propName], b[propName])) return false
        break
      default:
        if (a[propName] !== b[propName]) return false
        break
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true
}

export function concatWithAnd(array, andString) {
  if (array.length === 0) return ''
  if (array.length === 1) return array[0]
  if (array.length === 2) return array.join(' ' + andString + ' ')
  return array.slice(0, -1).join(', ') + ' ' + andString + ' ' + array[array.length - 1]
}
/**
 * capitalize string
 * @param {string} str 
 * @returns 
 */
export function capitalize(str) {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}