<template>
  <div class="login-card">
    <div class="title">{{ $t('login_title') }}</div>
    <div class="sub-title">{{ $t('login_sub_title') }}</div>
    <div class="section">
      <form autocomplete="on" @submit.prevent="handleSubmit">
        <p class="required-text">* {{ $t('login_required') }}</p>
        <basic-input
          id="email"
          v-model="values.username"
          class="input-login-card"
          required
          type="email"
          :error="errors.username"
          :show-icon="blur.username"
          :right="!errors.username"
          autocomplete="email"
          :placeholder="$t('username')"
          @blur="validateLogin('username')"
        />
        <basic-input
          id="password"
          v-model="values.password"
          class="input-login-card"
          :show-icon="false"
          autocomplete="current-password"
          required
          :type="type"
          :placeholder="$t('password')"
          @blur="validateLogin('password')"
        >
          <div 
            class="icon-eye" 
            :class="[type==='password' ? 'open':'close']"  
            @click="handleClickEye"
          />
        </basic-input>
        <p class="input-footer forgot-password">{{ $t('login_password_forgot') }}</p>
        <button type="submit" class="button btn--tertiary">{{ $t('login_log_in_button') }}</button>
      </form>
    </div>
    <hr>
    <div class="section">
      <div class="title">
        {{ $t('login_new_in_douglas_title') }}
      </div>
      <button class="button btn--tertiary">{{ $t('login_new_in_douglas_button') }}</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as yup from 'yup'
import BasicInput from './basic/BasicInput.vue'

export default {
  components: { BasicInput },
  data() {
    return {
      type:'password',
      values: {
        username: '',
        password: '',
        stayLogged: false,
      },
      errors: {
        username: '',
        password: '',
      },
      blur: {
        username: false,
        password: false,
      },
    }
  },
  computed: {
    loginSchema() {
      return yup.object().shape({
        username: yup
          .string()
          .email(this.$t('login_username_not_email'))
          .required(this.$t('login_username_required')),
        password: yup
          .string()
          .required(this.$t('login_password_required'))
          .min(8),
      })
    },
  },
  methods: {
    ...mapActions({ login: 'profiler/login' }),
    validateLogin(field) {
      this.blur[field] = true
      this.loginSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = ''
        })
        .catch(err => {
          this.errors[field] = err.message
          console.warn(field, err)
        })
    },
    handleSubmit() {
      const { username, password } = this.values
      this.login({ user: username, password }).then(() => {
        this._finshSubmit()
      })
    },
    _finshSubmit() {},
    handleClickEye(){
      if(this.type==='password') this.type = 'text'
      else this.type='password'
    }
  },
}
</script>

<style lang="stylus" scoped>
.login-card
  width: 100%
  height: 100%
  .section
    margin-top: vh(20px)
    .title
      m-font-size(20,25)
      font-weight: 600
  >>>form
    width: 100%
    .input-login-card
      width 40vw
      .mobile &
        width 90%
    .input-footer
      m-font-size(14,17)
  .button
    width 100%
    .mobile &
      height: vh(100px)
  .icon-eye
    position: absolute
    top: 50%
    right: vw(15px)
    transform: translateY(-50%)
    &.open
      m-icon("eye-open", 30)
      .mobile &
        m-icon("eye-open", 25)
    &.close
      m-icon("eye-close", 30)
      .mobile &
        m-icon("eye-close", 25)
</style>